import React, { Component } from 'react'
import Footer from '../Components/Footer';
import Topbar from '../Components/Topbar';
import wheel from '../images/wheel2.png';
import { Link } from 'react-router-dom';
import lotusHq from '../images/lotusHqTransparent.png';
import lotusHq2 from '../images/lotusHqTransparent2.png';

class About extends Component {
    render() {
        return (
            <div className="mainWrapper">
                {/* <Topbar /> */}
                <Link className="corner cornerArrowTR" to='/'>
                    {/* <img src="https://cdn-icons-png.flaticon.com/512/60/60683.png" alt="" /> */}
                    <img src={wheel} alt="" />
                    <span>Home</span>
                </Link>
                <div className="contentContainer">
                    {/* <div className="broucher">
                        <span className="title">tarot with dr. Shoba</span>
                        <div className="image">
                            <img src="https://static.wixstatic.com/media/11062b_7d8d82eae2a14cf9891836867f4e9726~mv2.jpg/v1/fill/w_574,h_435,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/11062b_7d8d82eae2a14cf9891836867f4e9726~mv2.jpg" alt="Broucher" />
                        </div>
                    </div> */}
                    {/* <img src={lotusHq} alt="Lotus" className="myStoryBg"/> */}

                    <div className="myStory">
                        <div className="stripedLotus">
                            <div className="circle top">
                                <img src={lotusHq} alt="Lotus" />
                            </div>
                            <div className="circle" style={{ backgroundColor: "#6B705C" }}>
                                <img src={lotusHq2} alt="Lotus" />
                            </div>
                            <div className="circle">
                                <img src={lotusHq} alt="Lotus" />
                            </div>
                        </div>
                        <div className="content">
                            <h1>a journey with Tarot</h1>

                            <h1 style={{ fontSize: "40px" }}>academia & healing - intuition & intellect </h1>

                            <p>In 2009, I completed my PhD thesis: ‘Navigating Cultural Spaces’. On reflection, I can see my life has been, and continues to be, a process of navigating cultural spaces. Born in England, to parents who had emigrated from India,
                                as a family we relocated 18 months later to Aotearoa New Zealand. With the exception of a two year period in Saudi Arabia,
                                I consider myself blessed to have grown up in Aotearoa. Yet I have always been proud of my Indian heritage and in 2014 felt the need to honour the call to ‘return home’. Currently I live in Chennai, South India.</p>

                            <p>I have been drawn to ‘healing’ work throughout my life, not surprising really – I come from a family of health professionals! My own journey initially took me down the path of occupational therapy where I practiced as
                                a clinician in a variety of mental health service settings in Aotearoa New Zealand. From this base I have extended my practice in the realms of healing work through various training in energy healing, counselling,
                                and working in the Akashic Records.</p>

                            <p>Following completion of my PhD, I moved from clinical work to academia, and since that time have held various roles in academic institutions in New Zealand, the UK, and India. Currently I am self-employed working with individuals and groups offering services including training and support primarily in the areas of ‘qualitative research’ and ‘personal growth’.</p>

                            <p>At the start of 2022, I gained my certification as a Tarot Advisor through Biddy Tarot and Diploma in Tarot from Tarot for Women. This
                                was the motivation needed to embark on another chapter in my life.  <span style={{ color: "black", fontSize: "18px", fontWeight: "600", fontStyle: "italic" }}>I believe Tarot is a tool that allows me to combine my two worlds of academia and healing</span>. Drawing on my skills of logical thinking, written communication, intuition, and empathy, I offer a unique way to support people in their journey. All I ask is an open heart and mind as you receive the messages from Tarot with my guidance.</p>
                            {/* <p>At the start of 2022, I gained my certification as a Tarot Advisor through BiddyTarot. This was the motivation needed to embark on another chapter in my life. <span style={{ color: "#6b705c", fontSize: "21px", fontWeight: "500" }}>I believe Tarot is a tool </span>
                                that allows me to combine my two worlds of healing and academia on a daily basis; thus, drawing on my skills of logical thinking, written communication, intuition, and empathy, I am able
                                to offer a unique way to <span style={{ color: "#6b705c", fontSize: "21px", fontWeight: "500" }}>support people in their journey</span>. All that I ask of you is your willingness to receive the messages from Tarot, as well as my guidance, with an open heart and mind.</p> */}
                        </div>

                    </div>

                    <Link to='/readings' className='btn'>Discover Your Reading</Link>

                </div>


                <Footer />
            </div>
        )
    }
}
export default About;