import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import wheel from '../images/wheel2.png';

class Topbar extends Component {
    render() {
        return (<div className="topBar">
            <div className="title">tarot with dr. Shoba</div>
            <Link className="wheelOfFortune" to="/">
                <img src={wheel} alt="" />
            </Link>
            <span style={{fontWeight:"600"}}>Home</span>
        </div>
        )
    }
}
export default Topbar;