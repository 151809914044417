import React, { Component } from 'react'
import Footer from '../Components/Footer';
import Topbar from '../Components/Topbar';
import wheel from '../images/wheel2.png';
import { Link } from 'react-router-dom';
import lotusHq from './images/lotusHqTransparent.png';
import lotusHq1 from './images/lotusHqTransparent1.png';
import lotusHq2 from './images/lotusHqTransparent2.png';
import {
    SendOutlined,
    LogoutOutlined
} from "@ant-design/icons";// import DatePicker from 'react-date-picker';
// import DatePicker from 'react-date-picker/dist/entry.nostyle';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/firestore";

// import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/firestore";

import 'firebase/compat/functions'
import 'firebase/compat/storage'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { Table, Modal, Tooltip } from 'antd';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB1DwkuTqtfRdI57vnCa6G1rBKQEJxyaYA",
    authDomain: "tarrot-e2d8b.firebaseapp.com",
    projectId: "tarrot-e2d8b",
    storageBucket: "tarrot-e2d8b.appspot.com",
    messagingSenderId: "202713799525",
    appId: "1:202713799525:web:1cf9fff169d396a2c61f8c",
    measurementId: "G-EWZHY996TV"
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
firebase.auth().languageCode = 'en';

interface PostBlogState {
    values: {
        title: string,
        image: any,
        date: string,
        post: string,
    }
    blogs: any
    messages: any
    visible: boolean
}
class MyContactBox extends Component<{}, PostBlogState> {
    constructor(props: any) {
        super(props);
        this.state = {
            values: {
                title: "",
                image: "",
                date: "",
                post: ""
            },
            blogs: [],
            messages: [],
            visible: false
        };
    }
    columns: any = [
        {
            title: "Date Submitted",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "From",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "First Name",
            dataIndex: "fname",
            key: "fname",
        },
        {
            title: "Last Name",
            dataIndex: "lname",
            key: "lname",
        },
        {
            title: "DOB",
            dataIndex: "dob",
            key: "dob",
        },
        {
            title: "Selected Reading",
            dataIndex: "reading",
            key: "reading",
        },
        {
            title: "Message",
            dataIndex: "info",
            key: "info",
            ellipsis: {
                showTitle: false,
            },
            render: (info: any) => (
                <Tooltip placement="left" title={info}>
                    {info}
                </Tooltip>
            ),
        },
        // render: (message: any) => {
        //    return <span style={{cursor:"pointer"}} onClick={(e) => this.setVisible(e,true)}>
        //         {message}
        //     </span>
        // }
        // },
        // {
        //     title: "Action",
        //     dataIndex: "email",
        //     key: "email",
        //     render: (email: any, record: any) => {
        //         return <a href={`mailto:${email}`}>
        //             <SendOutlined />
        //         </a>
        //     }
        // },
    ]
    setVisible = (e: any, mode: any) => {
        e.preventDefault();
        this.setState({ visible: mode });
    }
    deletePost = (text: any) => {
        const db = (firebase as any).firestore();

        var jobskill_query = db.collection('posts').where('title', '==', text);
        console.log(jobskill_query, 'll')
        // jobskill_query.get().then(function (querySnapshot: any[]) {
        //     querySnapshot.forEach(function (doc) {
        //         doc.ref.delete();
        //     });
        // });
    }
    handleInput = (e: any) => {
        const { name, value } = e.target;

        if (name === "image") {

            const file = e.target.files[0];
            const extension = value.substring(file.name.lastIndexOf('.') + 1);
            const fileName = +(new Date());
            const storage = getStorage();
            const storageRef = ref(storage, 'images/' + fileName + extension);
            this.setState({
                values: { ...this.state.values, [name]: 'images/' + fileName + extension },
            });

            uploadBytes(storageRef, file).then((snapshot) => {
                console.log('Uploaded a blob or file!');
            });
            console.log('Uploaded a blob or file!');

        } else {

            this.setState({
                values: { ...this.state.values, [name]: value },
            });
            console.log(this.state.values, "Values")
        }

    };


    logOut = () => {
        localStorage.clear()
        window.location.href = "/admin"
    }



    componentDidMount() {
        const imageRef = 'images/1658122863285.jpg';
        const storage = getStorage();

        // get this from the main page
        getDownloadURL(ref(storage, imageRef))
            .then((url) => {
                const img = document.getElementById('myimg')!;
                // console.log(img)
                img.setAttribute('src', url);
            })
            .catch((error) => {
            });

        const db = (firebase as any).firestore();

        db.collection("contacts").get()
            .then((docRef: any) => {
                console.log("Document written with ID: ", docRef.docs.map((doc: any) => doc.data()))
                this.setState({ messages: docRef.docs.map((doc: any) => doc.data()) })
            })
            .catch((error: any) => {
                console.error("Error adding document: ", error);
            });

        db.collection("contacts").get()
            .then((docRef: any) => {
                this.setState({
                    blogs: docRef.docs.map((doc: any) => doc.data()).map((data: any, idx: any) => {
                        return { ...data, id: idx }
                    })
                })
                console.log("Postss", docRef.docs.map((doc: any) => doc.data())/* docs[0]._delegate._document.data.value.mapValue.fields */);
            })
            .catch((error: any) => {
                console.error("Error: ", error);
            });


    }



    render() {
        return (
            <div className="mainWrapper">
                <Link className="cornerArrowTR" to='/admin/postBlog'>
                    <img src={wheel} alt="" />
                    <span>Post Blog</span>
                </Link>

                <span className="cornerArrowTL" style={{ marginTop: "5px" }} onClick={(e) => this.logOut()}>
                    <LogoutOutlined style={{ fontSize: 35 }} />
                    <span >Log Out</span>
                </span>
                <div className="contentContainer">
                    <div className="intro">
                        <h1>My Contact Box</h1>
                        {/* <div className="text">
                            If you know what type of reading you would like, or if you would like some more information, please complete the form below.
                            Once I receive your message, I will be in touch to acknowledge confirmation and to clarify any details if necessary.After we agree on your question/type of reading,
                            I will send you an invoice via PayPal. As soon as payment has been received I will prepare your reading and will email it to you within 5 days.
                        </div> */}
                    </div>

                    {/* <h2>Let's Connect</h2> */}

                    <div className="contactBox">
                        <Table columns={this.columns} dataSource={this.state.messages} />
                    </div>

                </div>
                <Footer />
            </div>
        )
    }
}
export default MyContactBox;