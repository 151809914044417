import React, { Component } from 'react'
import Footer from '../Components/Footer';
import Topbar from '../Components/Topbar';
import wheel from '../images/wheel2.png';
import { Link } from 'react-router-dom';
import lotusHq from './images/lotusHqTransparent.png';
import lotusHq1 from './images/lotusHqTransparent1.png';
import lotusHq2 from './images/lotusHqTransparent2.png';
import { notification } from 'antd';
// import DatePicker from 'react-date-picker';
// import DatePicker from 'react-date-picker/dist/entry.nostyle';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import firebase from "firebase/compat/app";
import "firebase/firestore";
import{ Navigate }from 'react-router-dom';

interface PostBlogState {
    values: {
        username: string,
        password: string,
    }
}
class PostBlog extends Component<{}, PostBlogState> {
    constructor(props: any) {
        super(props);
        this.state = {
            values: {
                username: "",
                password: "",
            }
        };
    }
    handleInput = (e: any) => {
        this.setState({
            values: { ...this.state.values, [e.target.name]: e.target.value },
        });
    };
    // submitContactForm = (e: any) => {
    //     e.preventDefault();
    //     console.log(this.state.values, "Form Values");

    //     const un = "admin@admin.com";
    //     const pw = "admin@123";


    // }
    /// Create a login page
    //get the details fomr a login screen
    login = (e:any) => {
        e.preventDefault();
        const auth = getAuth();
        signInWithEmailAndPassword(auth,this.state.values.username, this.state.values.password)
        // signInWithEmailAndPassword(auth, "shobha@tarrot.com", "Tarrot@312")

            .then((userCredential) => {
                // Signed in 
                const user:any = userCredential.user;
                localStorage.setItem("accessToken",user.accessToken);
                notification['success']({
                    message: 'Logging In',
                    // duration: 4.5

                });
                window.location.href="/admin/postBlog"
                // <Navigate replace to={"/admin/postBlog"}/>
                // console.log(user.accessToken,'auth')
                // localStorage.setItem("user",user);
                // ...
            })
            .catch((error) => {
                notification['error']({
                    message: 'There is Something Wrong',
                    // duration: 4.5

                });
                const errorCode = error.code;
                const errorMessage = error.message;
            });
    }



    render() {
        return (
            <div className="mainWrapper">
                {/* <Link className="cornerArrowTL" to='/'>
                    <img src={wheel} alt="" />
                    <span>Home</span>
                </Link> */}
                <div className="contentContainer">
                    <div className="intro">
                        <h1>Login Here</h1>
                        {/* <div className="text">
                            If you know what type of reading you would like, or if you would like some more information, please complete the form below.
                            Once I receive your message, I will be in touch to acknowledge confirmation and to clarify any details if necessary.After we agree on your question/type of reading,
                            I will send you an invoice via PayPal. As soon as payment has been received I will prepare your reading and will email it to you within 5 days.
                        </div> */}
                    </div>

                    {/* <h2>Let's Connect</h2> */}

                    <div className="contactBox">
                        {/* <div className="mailSection">
                            <span>email</span>
                            <a href="mailto:contact@shobanayar.com">contact@shobanayar.com</a>
                        </div> */}
                        <div className="contactForm" style={{ width: "100%" }}>
                            <form action="" method="post">
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <input type="text" name='username' placeholder='Username' onChange={(e) => this.handleInput(e)} />
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <input type="password" name='password' placeholder='Password' onChange={(e) => this.handleInput(e)} />
                                    </div>

                                    <div className="col-md-12 mb-3" style={{ textAlign: "center" }}>
                                        <button className='btn btn-dark' onClick={(e) => this.login(e)}>Login</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {/* <div className="stripedLotus">
                            <div className="circle top">
                                <img src={lotusHq2} alt="Lotus" />
                            </div>
                            <div className="circle" style={{ backgroundColor: "transparent" }}>
                                <img src={lotusHq} alt="Lotus" />
                            </div>
                            <div className="circle">
                                <img src={lotusHq2} alt="Lotus" />
                            </div>
                        </div> */}

                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
export default PostBlog;