import React, { Component } from 'react'

class CodeOfEthics extends Component {
    render() {
        return (
            <div className="mainWrapper">
                {/* <div className="topBar">
                    <div className="title">tarot with dr. Shoba</div>
                    <Link className="wheelOfFortune" to="/contact">
                        <img src={wheel} alt="" />
                    </Link>
                    <span style={{ fontWeight: "600" }}>Contact</span>
                </div> */}
                <div className="contentContainer">
                    <div className="codeOfEthics">
                        <h3 className="title">Code Of Ethics</h3>

                        <span>In providing the services of a Tarot reader I undertake the following:</span>
                        <br /><br />

                        <ul>
                            <li>I will accurately represent myself and my experience and knowledge in order to serve the best interests of my clients with professionalism and integrity.</li>
                            <li>I will protect the confidential nature of a person's information shared throughout our engagement, and will not share client information without prior approval. I will uphold the privacy of the client by ensuring all written information (e.g., recorded readings) is stored on a password protected drive on my computer to which only I have access.</li>
                            <li>To protect the privacy of others, I will not read on a third party (e.g., for someone whom I have not received direct consent). Where possible, I will rephrase the question to focus the reading on what is directly related to the client. If this is not possible or the client is not willing to change the question, I will not proceed with the reading.</li>
                            <li>I will share what I see in the Tarot cards with honesty and transparency. I will be direct in my explanations and always ensure I check in with clients to ascertain their understanding.</li>
                            <li>I will always provide readings with compassion and in a non-judgemental manner.</li>
                            <li>I will work with people to identify their aspirations and priorities in all areas of life and read the Tarot for guidance as agreed upon through collaborative discussion. However, if the client needs advice in any area for which I am not qualified (e.g., medical, legal, financial), I will inform the client as such and recommend they seek professional advice.</li>
                            <li>I will provide guidance and insight, but I will not make definitive predictions nor take away the free will of my clients. I will support my clients to take responsibility for their decisions and actions following a Tarot reading.</li>
                            <li>I will ensure clients are informed of my fee structure in advance of confirming and conducting a reading.</li>
                        </ul>
                    </div>

                    <div className="disclaimer">
                        <h3>Disclaimer</h3>
                        <div className="text">
                            <p>In accepting services from Shoba Nayar, the customer/client will not hold Shoba Nayar in any way responsible nor liable for any experience associated with any of Shoba’s services or products and any consequences arising there from, even in respect of any negligent act or omission (whether advisory or otherwise) occurring in the provision of and/or incidental to the provision of services or in respect of any product supplied by Shoba Nayar.</p>

                            <p>In addition, Shoba Nayar is not liable for information on sites that are linked to the Website. Further, Shoba Nayar is not responsible for any content contained on sites that are linked to the website.</p>
                        </div>
                    </div>

                    {/* <Link to='/contact' className='btn'>Book Your Reading</Link> */}

                </div>
                {/* <Footer /> */}
            </div>
        )
    }
}
export default CodeOfEthics;