import React, { Component } from 'react'
import Footer from '../Components/Footer';
import Topbar from '../Components/Topbar';
import wheel from '../images/wheel2.png';
import { Link } from 'react-router-dom';
import { Modal, DatePicker, notification } from 'antd';
// import { DatePicker, Space } from 'antd';
import lotusHq from '../images/lotusHqTransparent.png';
import lotusHq2 from '../images/lotusHqTransparent2.png';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import CodeOfEthics from '../Components/CodeOfEthics';
import ReCAPTCHA from "react-google-recaptcha";

// import firebase from 'firebase/compat';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/firestore";
// import DatePicker from 'react-date-picker';
// import DatePicker from 'react-date-picker/dist/entry.nostyle';

interface ContactState {
    todate: any
    isVerified: boolean
    values: {
        fname: string,
        lname: string,
        date: string,
        dob: string,
        email: string,
        reading: string,
        info: string,
        termsCheck: any,
    },
    visible: boolean

}
class Contact extends Component<{}, ContactState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isVerified: false,
            todate: "",
            values: {
                fname: "",
                lname: "",
                dob: "",
                date: "",
                email: "",
                reading: "",
                info: "",
                termsCheck: false,
            },
            visible: false

        };
    }
    setVisible = (e: any, mode: any) => {
        e.preventDefault();
        this.setState({ visible: mode, values: { ...this.state.values, termsCheck: true } });
    }
    handleInput = (e: any) => {
        this.setState({
            values: { ...this.state.values, [e.target.name]: e.target.value }
        });
    };
    handleDate = (e: any, record: any) => {
        console.log(e)
        const wholeDate = e._d;
        const date = wholeDate.getDate() + '-' + (wholeDate.getMonth() + 1) + '-' + wholeDate.getFullYear();
        const todate = date.toString();
        this.setState({
            values: { ...this.state.values, ["dob"]: todate }
        });
    };
    handleCaptcha = (e: any) => {
        this.setState({
            isVerified: true
        });
    };
    submitContactForm = (e: any) => {
        // e.preventDefault();
        // const { values } = this.state;

        e.preventDefault();

        var addContact = firebase.functions().httpsCallable('addContact');

        const db = (firebase as any).firestore();
        const { values } = this.state;
        const { fname, lname, dob, email, info, reading, termsCheck } = this.state.values;
        if (fname && lname && dob && email && info && reading) {
            //@todo dont directly copy from state, get only the values that you need
            addContact({ fname, lname, email, reading, dob, info })
                .then(async (result: any) => {
                    // notification['success']({
                    //     message: 'Thank You for contacting',
                    //     description:
                    //         'We will get back to you soon..',
                    //     duration: 4.5
                    // });

                    await fetch("https://us-central1-tarrot-e2d8b.cloudfunctions.net/email", {
                        method: 'POST', // *GET, POST, PUT, DELETE, etc.
                        // mode: 'cors',
                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                        // credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Content-Type': 'application/json',
                            "Access-Control-Allow-Origin": "*",


                            // 'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        redirect: 'follow', // manual, *follow, error
                        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                        body: JSON.stringify({ firstname: fname, lastname: lname, email: email }) // body data type must match "Content-Type" header
                    }).then((ref) => {
                        notification['success']({
                            message: 'Thank You for contacting',
                            description:
                                'We will get back to you soon..',
                            duration: 4.5
                        });
                        window.location.href = "/contact";
                    }).catch((err) => {
                        console.log(err, "err")
                        notification['error']({
                            message: 'There is something wrong',
                            duration: 4.5

                        });
                        window.location.href = "/contact";
                    });

                })
                .catch((error: any) => {
                    notification['error']({
                        message: 'There is something wrong DB',
                        duration: 4.5

                    });
                    // window.location.href = "/contact";
                });
        }
        else {
            notification['error']({
                message: 'There is something wrong',
                description:
                    'Please try again later..',
            });
            window.location.href = "/contact";

        }

        return true;
    }
    componentDidMount = () => {
        const today = new Date();
        console.log(today, "today")
        const date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
        const todate = date.toString();
        this.setState({ values: { ...this.state.values, date: todate } })

    }



    render() {
        const siteKey = "6LdiYB8hAAAAAKKZUDOG_vK3L5BTZeR3dj7ft4ND";
        return (
            <div className="mainWrapper">
                {/* <Topbar /> */}
                <Link className="corner cornerArrowTL" to='/'>
                    {/* <img src="https://cdn-icons-png.flaticon.com/512/60/60683.png" alt="" /> */}
                    <img src={wheel} alt="" />
                    <span>Home</span>
                </Link>
                <div className="contentContainer">
                    <div className="intro">
                        <h1>Ready To Start A Conversation?</h1>
                        {/* <div className="text">
                            If you know what type of reading you would like, or if you would like some more information, please complete the form below.
                            Once I receive your message, I will be in touch to acknowledge confirmation and to clarify any details if necessary.After we agree on your question/type of reading,
                            I will send you an invoice via PayPal. As soon as payment has been received I will prepare your reading and will email it to you within 5 days.
                        </div> */}
                    </div>

                    <h2>Let's Connect</h2>

                    <div className="contactBox">
                        {/* <div className="mailSection">
                            <span>email</span>
                            <a href="mailto:contact@shobanayar.com">contact@shobanayar.com</a>
                        </div> */}
                        <Modal
                            // title="Modal 1000px width"
                            centered
                            visible={this.state.visible}
                            onOk={(e) => this.setVisible(e, false)}
                            onCancel={(e) => this.setVisible(e, false)}
                            width={1000}
                            bodyStyle={{ padding: 0, background: "#FFE8D6" }}
                            style={{ backgroundColor: "#FFE8D6", margin: "20px" }}
                            okText="Agree"
                            cancelText="-"
                            footer={null}
                        >
                            <CodeOfEthics />

                            <div className="col-md-12 mb-3 form-check" style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                <input type="checkbox" id="check" name="termsCheck" value="Yes" style={{ marginRight: "5px" }} onChange={(e) => e.target.checked && this.submitContactForm(e)} />

                                <label htmlFor="check"> I have read the <span style={{ textDecoration: "none", fontSize: "18px", color: "black" }}>code of ethics and disclaimer</span> </label>
                            </div>

                        </Modal>
                        <div className="contactForm">
                            <form>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <input type="text" name='fname' placeholder='First Name' onChange={(e) => this.handleInput(e)} />
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <input type="text" name='lname' placeholder='Last Name' onChange={(e) => this.handleInput(e)} />
                                    </div>

                                    <div className="col-md-12 mb-3 date">
                                        {/* <input type="date" name="date" id="DATE" className='form-control' placeholder='dd/MM/yyyy' onChange = {(e) => this.handleInput(e)} /> */}
                                        {/* <span>Click on the calender icon to select date</span> */}
                                        {/* <DatePicker format='dd-MM-yyyy'/> */}
                                        {/* {!this.state.values.date &&
                                            <label htmlFor="">Date Of Birth</label>
                                        } */}
                                        <DatePicker onChange={(e, record) => this.handleDate(e, record)} style={{ width: "100%" }} placeholder={"Select Date Of Birth"} />

                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <input type="email" name='email' placeholder='Email' onChange={(e) => this.handleInput(e)} />
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <select name='reading' placeholder='What are you interested in?' className='form-select' onChange={(e) => this.handleInput(e)}>
                                            <option value="">Select Your Reading</option>
                                            <option value="Forthcoming">Forthcoming Year - $107</option>
                                            <option value="3to5">General Reading - $75</option>
                                            <option value="6to8">One Question - $45 </option>
                                            <option value="Birthday">Birthday Special - $60 </option>
                                            <option value="1Card"> Tarot &amp; Akashic Records Reading - $157</option>
                                            {/* <option value="Design a Reading">Design a Reading </option> */}
                                        </select>
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <input type="text" name='info' placeholder='Please provide your question and any supporting information' style={{ height: "150px" }} onChange={(e) => this.handleInput(e)} />
                                    </div>




                                    <div className="col-md-12 mb-3" style={{ textAlign: "center" }}>
                                        <ReCAPTCHA style={{ display: "flex", justifyContent: "center" }}
                                            sitekey={siteKey}
                                            onChange={(e) => this.handleCaptcha(siteKey)}
                                        />
                                        {/* {this.state.values.termsCheck ? */}
                                        {/* <button className='btn btn-dark' onClick={(e) => this.submitContactForm(e)}>Submit</button> */}
                                        <button className='btn btn-dark' onClick={(e) => this.setVisible(e, true)} disabled={!this.state.isVerified}>Submit</button>

                                        {/* :
                                            <button className='btn btn-dark' onClick={(e) => this.setVisible(e, true)} disabled={!this.state.isVerified}>Submit</button>

                                        } */}
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="stripedLotus">
                            <div className="circle top">
                                <img src={lotusHq2} alt="Lotus" />
                            </div>
                            <div className="circle" style={{ backgroundColor: "transparent" }}>
                                <img src={lotusHq} alt="Lotus" />
                            </div>
                            <div className="circle">
                                <img src={lotusHq2} alt="Lotus" />
                            </div>
                        </div>


                        {/* <div className="image">
                            <img src="https://static.wixstatic.com/media/a3c153_052670d4a1004f3b931015dc851ae725~mv2.jpg/v1/fill/w_334,h_990,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/a3c153_052670d4a1004f3b931015dc851ae725~mv2.jpg" alt="" />
                        </div> */}
                    </div>
                </div>

                <Footer />
            </div>
        )
    }
}
export default Contact;