import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class Footer extends Component {
    render() {
        return (<div className="footer">
            <span>©2022 tarot with dr. Shoba</span>
            <a href='mailto:contact@shobanayar.com'>contact@shobanayar.com</a>
        </div>
        )
    }
}
export default Footer;