import React, { Component } from 'react'
import Footer from '../Components/Footer';
import wheel from '../images/wheel2.png';
import lotusHq from '../images/lotusHq.png';
import { Link } from 'react-router-dom';
// import CodeOfEthics from './CodeOfEthics';

// const card = [

//     { title: "Forthcoming Year - $57 ",marginLeft:"4%", detail: "In this reading learn about the overall energy and learnings for the upcoming 12 months - including potential challenges, how to overcome these, and surfacing opportunities; as well as a closer look at the energies of each quarter.", img: "https://static.wixstatic.com/media/11062b_5af5de22e8fb46e19844ace3cff7023b~mv2.jpg/v1/fill/w_310,h_282,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Colorful%20Fireworks.jpg"},
//     { title: "3-5 Card Reading - $37 ", detail: "This reading is best suited to 1 or 2 questions about a specific situation.", img: "https://static.wixstatic.com/media/11062b_99aeafa7f19247d6983bb32a3568a600~mv2.jpg/v1/fill/w_310,h_282,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Fragile%20Pampas.jpg"},
//     { title: "6-8 Card Reading - $57", detail: "Choose this reading if you would like a more general reading (e.g., an upcoming year reading) or have 2-3 specific questions for which you are seeking in-depth guidance.", img: "https://static.wixstatic.com/media/11062b_c39434af7e0c46639ffe9d05540abbbb~mv2.jpg/v1/crop/x_0,y_697,w_2404,h_2909/fill/w_310,h_282,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Pampas%20Grass.jpg"},
//     { title: "Birthday Special - $37 ", detail: "This reading provides you with your life card, year card and ego card.", img: "https://static.wixstatic.com/media/11062b_64647d2fcdaf44b98061f088b5abe16b~mv2.jpeg/v1/fill/w_310,h_282,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/National%20Flower.jpeg"},
//     { title: "1 Card Reading - $7", detail: "– The perfect reading if you want quick guidance on a particular situation or you wish to simply receive a message from the Universe.", img: "https://static.wixstatic.com/media/11062b_35f69204d1934ecabf464ac4cbf6b08e~mv2.jpg/v1/fill/w_310,h_282,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Flower.jpg"},
// ]
// const card = [
//     { title: "Forthcoming Year - $77 ", detail: "In this reading learn about the overall energy and potential learning for the next 12 months - including potential challenges, how to overcome these, and surfacing opportunities. Included is a close look at the energies for each quarter.", img: lotusHq },
//     { title: "3-5 Card Reading - $37 ", detail: "This reading is best suited to 1 or 2 questions about a specific situation.", img: lotusHq },
//     { title: "6-8 Card Reading - $57", detail: "Choose this reading if you would like a more general reading (e.g., an upcoming year reading) or have 2-3 specific questions for which you are seeking in-depth guidance.", img: lotusHq },
//     { title: "Birthday Special - $37 ", detail: "This reading provides you with your life card, year card and ego card.", img: lotusHq },
//     { title: "1 Card Reading - $17", detail: "The perfect reading if you want quick guidance on a particular situation or you wish to simply receive a message from the Universe.", img: lotusHq },
// ]
const card = [
    { title: "Forthcoming Year - $107 ", detail: "Learn about the overall energy for the next 12 months - including potential challenges, how to overcome these, and surfacing opportunities – and particular energies for each quarter.", img: lotusHq },
    { title: "General Reading - $75 ", detail: "Best suited for guidance on 2 questions. I will use 2 Tarot spreads specific to you and your issue.", img: lotusHq },
    { title: "One Question - $45", detail: "Have a simple question regarding a specific issue? This is a great choice for some insight and support.", img: lotusHq },
    { title: "Birthday Special - $60", detail: "Unlock your life, year, and soul cards. A perfect gift for yourself or someone else!", img: lotusHq },
    { title: "Tarot & Akashic Records Reading - $157", detail: "A magical combination for a deep dive into a situation that is troubling you. I’ll do a Tarot spread and open your Akashic Records to provide you with an in-depth understanding of the situation and how you can move forward.", img: lotusHq },
]
class Readings extends Component {
    render() {
        return (
            <div className="mainWrapper">
                {/* <Topbar /> */}
                <Link className="corner cornerArrowBL" to='/'>
                    {/* <img src="https://cdn-icons-png.flaticon.com/512/60/60683.png" alt="" /> */}
                    <img src={wheel} alt="ss" />
                    <span>Home</span>
                </Link>
                <div className="contentContainer">
                    {/* <h1 className='cAR'>Choose a Reading</h1> */}
                    <div className="readingsPage">
                        <div className="readings">
                            <h1 className='cAR'>Choose a Reading</h1>
                            <p>I currently provide written Tarot readings which will be emailed directly to you. &nbsp; I receive messages from Spirit best when I am writing; and,
                                as my readings are comprehensive, the written format provides you with an indepth record for you to refer to time and again as you study the guidance offered by Tarot.</p>

                            <p>I offer a range of different readings. However, if you do not find one that resonates with you, please contact me and I will be happy to work with you to create a more personalised reading spread.</p>

                            <p>You will receive your written Tarot reading with 5 days from when I receive your booking.</p>
                            {/* <p>To book your reading, please complete the form on the <Link to='/contact' style={{ textDecoration: "underline", color: "black", fontSize: "24px", fontWeight: "800" }}>book your reading</Link> page.</p> */}
                            <Link to='/contact' className='btn'>Book Your Reading</Link>

                        </div>


                        <div className="cards">
                            {card.map((card: any) => {
                                return (<div className="cardd" style={{ marginLeft: card.marginLeft }}>
                                    <div className="image">
                                        <img src={card.img} alt={card.title} />
                                    </div>

                                    <div className="text">
                                        <div className="title">{card.title}</div>
                                        <div className="detail">{card.detail}</div>
                                    </div>
                                </div>)
                            })}
                        </div>

                    </div>

                    {/* <CodeOfEthics/> */}

                    {/* <Link to='/contact' className='btn'>Book Your Reading</Link> */}
                </div>
                <Footer />
            </div>
        )
    }
}
export default Readings;