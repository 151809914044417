import React, { Component } from 'react'
import Footer from '../Components/Footer';
import Topbar from '../Components/Topbar';
import wheelOnly from '../images/wheel2.png';
import { Link } from 'react-router-dom';
import lotusHq from '../images/lotusHq.png';
import 'animate.css';

(function () {
    var elements: string | any[] | NodeListOf<Element>;
    var windowHeight: number;

    function init() {
        elements = document.querySelectorAll('.hidden');
        windowHeight = window.innerHeight;
    }

    function checkPosition() {
        for (var i = 0; i < elements.length; i++) {
            var element = elements[i];
            var positionFromTop = elements[i].getBoundingClientRect().top;

            if (positionFromTop - windowHeight <= 0) {
                element.classList.add('animate__animated animate__fadeInUpBig');
                element.classList.remove('hidden');
            }
        }
    }

    window.addEventListener('scroll', checkPosition);
    window.addEventListener('resize', init);

    init();
    checkPosition();
})();

const testimonies = [
    {
        img: lotusHq,
        txt: "There is a great energy in Shoba which one will recognize, is of a much deeper realisation. I am so grateful for having met her; I was asking the Universe for some guidance and BOOM a friend introduced me to Shoba. I have met her before a few times in public forums where I saw a very systematic and methodical approach and in-depth articulation of things she wanted to convey, but I never spent time chatting with her until this boom-day I mentioned. We had a great time discussing beliefs, experiences and sharing information and resources. She was so kind enough to do a reading for me from which I got greater clarity, better understanding of things I wanted answers for. This provided confidence and assurance and also inner guidance of things I need to work on myself and my surroundings.Shoba, I wish you and your work the best so that it brings out the best of everyone. ",
        author: "Prema Sankar, India"
    },
    // {
    //     img: "https://static.wixstatic.com/media/11062b_4f0000f8d6084cb4a8026e8fe0683041~mv2.png/v1/fill/w_200,h_200,al_c,usm_0.66_1.00_0.01,enc_auto/Heart.png",
    //     txt: "Shoba did a tarot card reading for me last week. This was a great experience for me and was so easy. Sometimes it’s nice to seek confirmation about your future without spending a lot of time travelling to see the reader or actually spending time with the reader. I also found the fee affordable. There are lots of people charging money for this work and yet the quality is not always there. What I loved about my reading from Shoba is that she did the following:",
    //     author: "Sumi Devanesen"
    // },
    // {
    //     img: "https://static.wixstatic.com/media/nsplsh_6834335671746c6e563755~mv2_d_3455_5182_s_4_2.jpg/v1/fill/w_200,h_200,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Image%20by%20Daiga%20Ellaby.jpg",
    //     txt: "You provided wonderful insight with the Spirit and Universe as well as the Universe allowing me to completely understand and acknowledge what I need to continue doing, what areas I should focus more on as well",
    //     author: "XXX"
    // }
]
class Main extends Component {
    render() {
        return (
            <div className="mainWrapper">
                {/* <Topbar /> */}
                <Link className="corner cornerArrowBR" to='/'>
                    {/* <img src="https://cdn-icons-png.flaticon.com/512/60/60683.png" alt="" /> */}
                    <img src={wheelOnly} alt="" />
                    <span>Home</span>

                </Link>
                <div className="contentContainer">
                    <div className="hero">
                        <div className="sub">
                            <div className="image">
                                <img src="https://static.wixstatic.com/media/a01970_6c37115ae18545068ad5f4f5bdc8aaf1~mv2.jpg/v1/fill/w_560,h_660,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/20210304_084350.jpg" alt="Dr. Shoba" />
                            </div>
                            <div className="textBox">
                                <h1>Welcome...</h1>

                                <p>As a Tarot reader I draw upon my background as an academic and occupational therapist specialising in mental health to weave my natural skills of logical thinking, intuition, and empathy as a guide through the journey of life...</p>

                                <p>I believe the Tarot is a powerful tool that helps us reflect on ourselves and our situations to make empowered decisions that align with moving towards our highest potential. Using Tarot, I will help connect you to your Higher Self and Spirit Guides to get the answers you seek to transform your life as you get to better understand yourself and your circumstances.</p>

                                <p>I like to use the Tarot as a form of coaching and storytelling - a tool for growth and conversing with ourselves at a soul level. I invite you to be part of the conversation...</p>

                                {/* <a href="readings" className=''>Discover Your Reading</a> */}
                            </div>

                            <Link className='dyr' to="/readings">Discover Your Reading</Link>

                        </div>
                    </div>

                    {/* <Link className='dyr' to="/readings">Discover Your Reading</Link> */}

                    <div className="testimonies hidden">
                        {/* <h1 className="animate__animated animate__fadeInRightBig">An animated element</h1> */}

                        <h1>Testimonials</h1>
                        {/* <div className="testimonyBg">
                            <img src={dragonFly} alt="dragonFly" />
                        </div>
                        <div className="gradient">

                        </div> */}
                        {/* {testimonies.map((testimony: any) => {
                            return (<div className="testimony animate__animated animate__fadeInRightBig">
                                <div className="image">
                                    <img src={testimony.img} />
                                </div>
                                <div className="text">
                                    <div className="content">
                                        {testimony.txt}
                                    </div>
                                    <div className="author">{testimony.author}</div>
                                </div>
                            </div>)
                        })} */}
                        <div className="testimony animate__animated animate__fadeInRightBig">
                            <div className="image">
                                <img src={lotusHq} />
                            </div>
                            <div className="text">
                                <div className="content" style={{ textAlign: "right" }}>
                                    There is a great energy in Shoba which one will recognize, is of a much deeper realisation. I am so grateful for having met her; I was asking the Universe for
                                    some guidance and BOOM a friend introduced me to Shoba. I have met her before a few times in public forums where I saw a very systematic and methodical approach
                                    and in-depth articulation of things she wanted to convey, but I never spent time chatting with her until this boom-day I mentioned. We had a great time discussing beliefs,
                                    experiences and sharing information and resources. {/* <br /> */} She was so kind enough to do a reading for me from which I got greater clarity, better understanding of things I wanted answers for.
                                    This provided confidence and assurance and also inner guidance of things I need to work on myself and my surroundings.
                                    <br />
                                    Shoba, I wish you and your work the best so that it brings out the
                                    best of everyone.
                                </div>
                                <div className="author">Prema Sankar, India</div>
                            </div>
                        </div>
                        <div className="testimony animate__animated animate__fadeInLeftBig">
                            <div className="text">
                                <div className="content" style={{ textAlign: "left" }}>
                                    Shoba’s tarot reading was a great experience for me and was so easy. Sometimes it’s nice to seek confirmation about your future without spending a lot of time travelling to see the reader or actually spending time with the reader. What I loved about my reading from Shoba was:
                                    <br />
                                    <ol>
                                        <li>Shoba shared how she works with tarot and how it could help me. I found her transparent and real in terms of her own abilities and she asks the enquirer to reflect on their own values and energy (inner knowing) as well. </li>
                                        <li>Shoba positions the tarot as a helpful guide or coach to help us on our life path.  This is more helpful than giving a ‘fortune telling’ summary.</li>
                                        <li>My reading consisted of receiving a word document complete with images of all the cards and a summary of key and emergent themes as well as a timeline for guidance. Finally, my reading was summarised according to my question
                                            (I asked for the reading to give me some guidance over the next 6-12 months). I was also given an additional card and message from another pack. A nice touch. </li>
                                    </ol>
                                    Overall, this was a lovely, gentle reading that had a nice personal touch. It had an intimacy about it, and I felt nurtured, looked after and informed.
                                </div>
                                <div className="author">Tess Moeke-Maxwell, Aotearoa New Zealand</div>
                            </div>
                            <div className="image">
                                <img src={lotusHq} />
                            </div>
                        </div>

                        <div className="testimony animate__animated animate__fadeInRightBig">
                            <div className="image">
                                <img src={lotusHq} />
                            </div>
                            <div className="text">
                                <div className="content" style={{ textAlign: "right" }}>
                                    My introduction to Tarot and first reading was with Shoba. I did not know what to expect but she walked me through her process and immediately put me to ease. Shoba tells it like it is with grace and humor.
                                    She encouraged me to participate to help gain a deeper understanding of my situation and figure out how to put the readings to best use.  I have gone back to her multiple times since my first reading and
                                    I very happily recommend Shoba and encourage others to seek her services.

                                </div>
                                <div className="author">Soumya Srinivasan, United States</div>
                            </div>
                        </div>
                        {/* 1.	Shoba shared how she works with tarot and how it could help me. I found her transparent and real in terms of her own abilities and she asks the enquirer to reflect on their own values and energy (inner knowing) as well.
                        2.	Shoba positions the tarot as a helpful guide or coach to help us on our life path.  This is more helpful than giving a ‘fortune telling’ summary.
                        3.	My reading consisted of receiving a word document complete with images of all the cards and a summary of key and emergent themes as well as a timeline for guidance. Finally, my reading was summarised according to my question (I asked for the reading to give me some guidance over the next 6-12 months). I was also given an additional card and message from another pack. A nice touch.
                        Shoba’s reading gave me a sense of confirmation about some of the things that I was considering about my future. There are some things that I have been signalled to watch out for (thank you). Overall, this was a lovely, gentle reading that had a nice personal touch. It had an intimacy about it, and I felt nurtured, looked after and informed.
                        Thank you, Shoba! */}


                    </div>

                    {/* <div className="contactSection">
                        <div className="left">
                            <span>Contact Me</span>
                            <a className='contaaaact' href='mailto:contact@shobanayar.com'>contact@shobanayar.com</a>
                        </div>

                        <div className="right">
                            <form action="" method="post">
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <input type="text" name='name' placeholder='Name' />
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <input type="text" name='address' placeholder='Address' />
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <input type="email" name='email' placeholder='Email' />
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <input type="number" name='phpne' placeholder='Phone' />
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <input type="text" name='subject' placeholder='Subject' />
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <input type="text" name='message' placeholder='Type Your Message here..' style={{ height: "150px" }} />
                                    </div>

                                    <div className="col-md-12 mb-3" style={{ textAlign: "right" }}>
                                        <button className='btn btn-dark'>Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> */}
                </div>
                <Footer />
            </div>
        )
    }
}
export default Main;