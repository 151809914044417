import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './Pages/Main';
import About from './Pages/About';
import Readings from './Pages/Readings';
import Contact from './Pages/Contact';
import Quadrant from './Pages/Quadrant';
import Blogs from './Pages/Blogs';
import Blog from './Pages/Blog';
import PostBlog from './admin/PostBlog';
import Login from './admin/Login';
import MyContactBox from './admin/MyContactBox';


function App() {
  const auth = localStorage.getItem("accessToken");
  const [values, setValues] = useState([])

  const handleValue = (values: any) => {
    console.log('blogs from parent ', values)
    setValues(values)
  }
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/home' element={<Main />} />
          <Route path='/about' element={<About />} />
          <Route path='/readings' element={<Readings />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/blogs' element={<Blogs handleValue={handleValue} />} />
          <Route path='/blog/:id' element={<Blog blogs={values} />} />
          <Route path='/' element={<Quadrant />} />
          {auth &&
            <>
              <Route path='/admin/postBlog' element={<PostBlog />} />
              <Route path='/admin/myContactBox' element={<MyContactBox />} />
            </>
          }
          <Route path='/admin' element={<Login />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;