import React, { Component } from 'react';
// import { Editor, EditorState } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';

// import RichTextEditor from 'react-rte';
import Footer from '../Components/Footer';
import Topbar from '../Components/Topbar';
import wheel from '../images/wheel2.png';
import { Link } from 'react-router-dom';
import lotusHq from './images/lotusHqTransparent.png';
import lotusHq1 from './images/lotusHqTransparent1.png';
import lotusHq2 from './images/lotusHqTransparent2.png';
import { DeleteTwoTone, LogoutOutlined, EditTwoTone } from "@ant-design/icons";
import { notification, Collapse, Space } from 'antd';
// import React, {Component, PropTypes} from 'react';

// import DatePicker from 'react-date-picker';
// import DatePicker from 'react-date-picker/dist/entry.nostyle';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/firestore";

// import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/firestore";

import 'firebase/compat/functions'
import 'firebase/compat/storage'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { Table } from 'antd';
import TextBox from './TextBox';
const { Panel } = Collapse;

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB1DwkuTqtfRdI57vnCa6G1rBKQEJxyaYA",
    authDomain: "tarrot-e2d8b.firebaseapp.com",
    projectId: "tarrot-e2d8b",
    storageBucket: "tarrot-e2d8b.appspot.com",
    messagingSenderId: "202713799525",
    appId: "1:202713799525:web:1cf9fff169d396a2c61f8c",
    measurementId: "G-EWZHY996TV"
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
firebase.auth().languageCode = 'en';

interface PostBlogState {
    editId: string
    isEdit: boolean
    isTable: boolean
    updatedBlogs: any
    editorStateForUpdate: EditorState
    values: {
        title: string,
        image: any,
        date: string,
        post: string,
        postId: string,
        richPost: string
    }
    editorState: EditorState
    blogs: any
    richPost: any
}
// interface PostBLogsProps {
//     onChange: (val:any)=> void
// }
class PostBlog extends Component<{}, PostBlogState> {
    constructor(props: any) {
        super(props);
        // static propTypes = {
        //     onChange: PropTypes.func
        //   };
        this.state = {
            editorStateForUpdate: EditorState.createEmpty(),
            updatedBlogs: [],
            editId: "",
            isEdit: false,
            isTable: false,
            values: {
                title: "",
                image: "",
                date: "",
                post: "",
                postId: "",
                richPost: ""
            },
            editorState: EditorState.createEmpty(),
            richPost: "",
            blogs: []
        };
    }
    columns: any = [
        {
            title: "POST ID",
            dataIndex: "postId",
            key: "postId",
        },
        {
            title: "Date Posted",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Blog Title",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Post",
            dataIndex: "richPost",
            key: "post",
            ellipsis: {
                showTitle: false,
            },
            render: (info: string) => (
                <>{info?.replace(/<[^>]*>/g, "").slice(0, 300)}</>
                // console.log(info)
                // <Tooltip placement="left" title={info}>
                //     {info}
                // </Tooltip>
            ),
        },
        {
            title: "Action",
            dataIndex: "postId",
            key: "action",
            width: "10%",
            render: (text: any, record: any) => {

                return <>
                    <Space>
                        {/* {text} */}
                        <EditTwoTone onClick={(e) => this.editToggle(text, record)} />
                        <DeleteTwoTone onClick={(e) => this.deletePost(text)} />
                    </Space>
                </>
            }
        },
    ]
    onEditorStateChange = (editorState: any, update: any) => {
        if (update === "new") {
            this.setState({ editorState });
            const richPost = stateToHTML(this.state.editorState.getCurrentContent());
            this.setState({ richPost });
        }
        else {
            this.setState({ editorStateForUpdate: editorState });
            const richPost = stateToHTML(this.state.editorStateForUpdate.getCurrentContent());
            this.setState({ richPost });
            // console.log(richPost)
        }

    };
    deletePost = (text: any) => {
        const db = (firebase as any).firestore();

        var delete_query = db.collection('posts').where('postId', '==', text);
        delete_query.get().then(function (querySnapshot: any[]) {
            querySnapshot.forEach(function (doc) {
                doc.ref.delete().then((del: any) => {
                    notification['success']({
                        message: 'Post Deleted',
                    });
                    window.location.href = "/admin/postBlog"
                }).catch((err: any) => {
                    notification['error']({
                        message: 'Something Wrong',
                    });
                    window.location.href = "/admin/postBlog"
                });
            });

        });
    }
    editToggle = (id: any, record: any) => {
        console.log(record)
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

        const stringedRichPost1 = (record.richPost).replace(/<[^>]*>/g, "");
        const stringedRichPost = stringedRichPost1.replace(/&nbsp;/g," ");
        var editorStateForUpdate = EditorState.createWithContent(ContentState.createFromText(stringedRichPost))
        this.setState({ isEdit: true, values: record, editorStateForUpdate });
    }
    handleInput = (e: any) => {
        const { name, value, checked } = e.target;

        if (name === "image") {

            const file = e.target.files[0];
            const extension = value.substring(file.name.lastIndexOf('.') + 1);
            const fileName = +(new Date());
            const storage = getStorage();
            const storageRef = ref(storage, 'images/' + fileName + extension);
            this.setState({
                values: { ...this.state.values, [name]: 'images/' + fileName + extension },
            });

            uploadBytes(storageRef, file).then((snapshot) => {
                console.log('Uploaded a blob or file!');
            });
            console.log('Uploaded a blob or file!');

        }
        else if (name === "pinPost") {
            this.setState({
                values: { ...this.state.values, [name]: checked },
            });
        } else {

            this.setState({
                values: { ...this.state.values, [name]: value },
            });
            console.log(this.state.values, "Values")
        }

    };

    logOut = () => {
        localStorage.clear()
        window.location.href = "/admin"
    }



    componentDidMount() {

        const imageRef = 'images/1658122863285.jpg';
        const storage = getStorage();

        // get this from the main page
        getDownloadURL(ref(storage, imageRef))
            .then((url) => {
                const img = document.getElementById('myimg')!;
                // console.log(img)
                img.setAttribute('src', url);
            })
            .catch((error) => {
            });

        const db = (firebase as any).firestore();

        db.collection("posts").get()
            .then((docRef: any) => {
                docRef.docs.map((doc: any) => doc.data())
            })
            .catch((error: any) => {
                console.error("Error adding document: ", error);
            });

        db.collection("posts").get()
            .then((docRef: any) => {
                const blogs = docRef.docs.map((doc: any) => doc.data()).map((data: any, idx: any) => {
                    return { ...data, id: idx }
                })
                const sortedBlogs = blogs.sort((a: any, b: any) => {
                    console.log(a, b)

                    return a?.postId < b?.postId ? 1 : -1
                })
                this.setState({ blogs: sortedBlogs });
                console.log("sortedBlogs", sortedBlogs/* docs[0]._delegate._document.data.value.mapValue.fields */);
            })
            .catch((error: any) => {
                console.error("Error: ", error);
            });

        const today = new Date();
        const yyyy = today.getFullYear();
        let mm: any = today.getMonth() + 1; // Months start at 0!
        let dd: any = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        const formattedToday = dd + '/' + mm + '/' + yyyy;
        this.setState({ values: { ...this.state.values, date: formattedToday } })
    }
    toggleTable = (key: any) => {
        this.setState({ isTable: !this.state.isTable })
    }

    savePost = (e: any) => {

        e.preventDefault();
        const db = (firebase as any).firestore();
        const timeStamp: any = + new Date()
        const { values, richPost } = this.state;
        const { title, image, post } = this.state.values;

        if (title && image && richPost) {
            //@todo dont directly copy from state, get only the values that you need

            db.collection("posts").add({ ...values, postId: new Date().getTime(), richPost: richPost })
                .then((docRef: any) => {
                    console.log("Document written with ID: ", docRef.id);
                    notification['success']({
                        message: 'Post Uploaded',
                    });
                    window.location.href = "/admin/postBlog"

                })
                .catch((error: any) => {
                    notification['error']({
                        message: 'There is something wrong',
                    });
                });
        }
        else {
            notification['error']({
                message: 'Fill all fields',
            });
        }
    }
    saveUpdatedPost = (e: any, postId: any) => {

        e.preventDefault();


        const db = (firebase as any).firestore();
        const timeStamp: any = + new Date()
        const { values ,richPost} = this.state;
        const { title, image } = this.state.values;
        console.log(richPost)

        if (title && image && richPost) {
            //@todo dont directly copy from state, get only the values that you need

            // db.collection("posts").doc(postId).update({ values })
            //     .then((docRef: any) => {
            //         console.log("Document written with ID: ", docRef.id);
            //         notification['success']({
            //             message: 'Post Updated',
            //         });
            //         window.location.href = "/admin/postBlog"

            //     })
            //     .catch((error: any) => {
            //         notification['error']({
            //             message: 'There is something wrong',
            //         });
            //     });

            var update_query = db.collection('posts').where('postId', '==', postId);
            update_query.get().then(function (querySnapshot: any[]) {
                querySnapshot.forEach(function (doc) {
                    doc.ref.update({...values,["richPost"]:richPost}).then((edited: any) => {
                        notification['success']({
                            message: 'Post Updated',
                        });
                        window.location.href = "/admin/postBlog"
                    }).catch((err: any) => {
                        notification['error']({
                            message: 'Something Wrong',
                        });
                    });
                })

            });
        }
        else {
            notification['error']({
                message: 'Fill all fields',
            });
        }
    }


    render() {
        const { isEdit } = this.state;
        let contentState = stateFromHTML("<p>Hiii</p>");
        console.log(contentState)
        return (
            <div className="mainWrapper">
                <Link className="cornerArrowTL" to='/admin/myContactBox'>
                    <img src={wheel} alt="" />
                    <span>My Contact Box</span>
                </Link>

                <span className="cornerArrowTR" style={{ marginTop: "5px" }} onClick={(e) => this.logOut()}>
                    <LogoutOutlined style={{ fontSize: 35 }} />
                    <span >Log Out</span>
                </span>
                <div className="contentContainer">
                    <div className="intro">
                        {isEdit ?
                            <h1>Update Your Blog Here</h1>
                            :
                            <h1>Post Your Blog Here</h1>

                        }
                        {/* <div className="text">
                            If you know what type of reading you would like, or if you would like some more information, please complete the form below.
                            Once I receive your message, I will be in touch to acknowledge confirmation and to clarify any details if necessary.After we agree on your question/type of reading,
                            I will send you an invoice via PayPal. As soon as payment has been received I will prepare your reading and will email it to you within 5 days.
                        </div> */}
                    </div>

                    {/* <h2>Let's Connect</h2> */}

                    <div className="contactBox">
                        <div className="contactForm" style={{ width: "100%" }}>
                            <form>
                                {!isEdit ?
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <input type="text" name='title' placeholder='Blog Title' onChange={(e) => this.handleInput(e)} required={true} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <input type="file" name='image' placeholder='Select Blog Cover' onChange={(e) => this.handleInput(e)} />
                                        </div>
                                        <div style={{ border: "1px solid black", padding: '2px', minHeight: '400px' }}>
                                            <Editor
                                                editorState={this.state.editorState}
                                                onEditorStateChange={(editorState: any) => { this.onEditorStateChange(editorState, "new") }}
                                            />
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <Space>
                                                <input type="checkbox" id="pinPost" name="pinPost" onChange={(e) => this.handleInput(e)} />
                                                <label htmlFor='pinPost'>Pin This Post</label>
                                            </Space>
                                        </div>


                                        <div className="col-md-12 mb-3" style={{ textAlign: "center" }}>
                                            <button className='btn btn-dark' onClick={(e) => this.savePost(e)}>Post</button>
                                        </div>
                                    </div>

                                    :

                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <input type="text" name='title' value={this.state.values.title} placeholder='Blog Title' onChange={(e) => this.handleInput(e)} required={true} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <input type="file" name='image' placeholder='Select Blog Cover' onChange={(e) => this.handleInput(e)} />
                                        </div>
                                        <div style={{ border: "1px solid black", padding: '2px', minHeight: '400px' }}>
                                            <Editor
                                                editorState={this.state.editorStateForUpdate}
                                                onEditorStateChange={(editorState: any) => { this.onEditorStateChange(editorState, "update") }}
                                            />
                                        </div>
                                        <div className="col-md-12 mb-3" style={{ textAlign: "center" }}>
                                            <button className='btn btn-dark' onClick={(e) => this.saveUpdatedPost(e, this.state.values.postId)}>Update</button>
                                        </div>
                                    </div>
                                }

                            </form>
                            {/* <TextBox/> */}
                            <div className='postsTable'>
                                <Collapse accordion>
                                    <Panel header="Click here to view your posts" key="1">
                                        <Table columns={this.columns} dataSource={this.state.blogs} />                                    </Panel>
                                </Collapse>
                                <img className='mt-3' id="myimg" style={{ width: "100%" }} />

                            </div>
                        </div>


                        {/* <div className="stripedLotus">
                            <div className="circle top">
                                <img src={lotusHq2} alt="Lotus" />
                            </div>
                            <div className="circle" style={{ backgroundColor: "transparent" }}>
                                <img src={lotusHq} alt="Lotus" />
                            </div>
                            <div className="circle">
                                <img src={lotusHq2} alt="Lotus" />
                            </div>
                        </div> */}

                    </div>

                </div>
                <Footer />
            </div>
        )
    }
}
export default PostBlog;