import React, { Component } from 'react'
import Footer from '../Components/Footer';
import Topbar from '../Components/Topbar';
import { Link } from 'react-router-dom';
import { PushpinFilled } from '@ant-design/icons';
import { Spin } from 'antd';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/firestore";

// import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/firestore";

import 'firebase/compat/functions'
import 'firebase/compat/storage'

interface BlogsState {
    blogs: any;
    updatedBlogs: any[]
    firstPost: any
}

interface BlogsProps {
    handleValue: (values: any) => void
}
class Blogs extends Component<BlogsProps, BlogsState> {
    state = {
        blogs: [],
        updatedBlogs: [],
        firstPost: {
            image: "",
            postId: "",
            title: "",
            richPost: ""
        }
    }
    componentDidMount = () => {
        const db = (firebase as any).firestore();

        const storage = getStorage();

        // get this from the main page


        db.collection("posts").get()
            .then((docRef: any) => {
                docRef.docs?.map((doc: any) => doc.data())?.map((obj: any) => {
                    getDownloadURL(ref(storage, obj?.image))
                        .then((url) => {

                            this.setState({ updatedBlogs: [...this.state.updatedBlogs, { ...obj, image: url }] })
                            // console.log({ ...obj, image: url },'url')



                            // this.setState({ firstPost: firstPost })
                            console.log("from data")
                        })
                        .catch((error) => {
                            console.log(error, 'eeee')
                        });
                })
                console.table("Postss Table", docRef.docs.map((doc: any) => doc.data())/* docs[0]._delegate._document.data.value.mapValue.fields */);
            })
            .catch((error: any) => {
                console.error("Error: ", error);
            });
    }
    render() {
        const img = "https://static.wixstatic.com/media/4422f85518ce4b08b3ba289d6e190b6f.jpg/v1/fill/w_454,h_341,fp_0.50_0.50,q_90,enc_auto/4422f85518ce4b08b3ba289d6e190b6f.webp";
        // const {title,postId,image,richPost}=this.state?.firstPost;


        const firstPost: any = this.state.updatedBlogs.length > 0 ? this.state.updatedBlogs?.filter((data: any) => data?.pinPost === true).sort((a: any, b: any) => {
            return a?.postId < b?.postId ? 1 : -1
        })[0] : {}



        console.log("from render")
        return (
            <div className="mainWrapper">
                {/* <Topbar /> */}

                <div className="contentContainer">

                    <Topbar />

                    {firstPost?.image ?
                        <div className="blogs">
                            {firstPost?.image &&
                                <>
                                    <div className="blogCard">
                                        <div className="image">
                                            <img src={firstPost?.image} alt={firstPost?.title} />
                                        </div>
                                        <div className="details">

                                            <div className="centerSection">
                                                <h3 className="blogTitle">{firstPost?.title}</h3>
                                                <div className="shortBrief">
                                                    <div dangerouslySetInnerHTML={{ __html: firstPost?.richPost.substr(0, 210).trim().concat("...") }} />
                                                    {/* {blog.richPost.slice(0, 180)}.. */}
                                                </div>
                                            </div>
                                            <div className="bottomSection">
                                                <div className="left">
                                                    {/* <div className="date">
                                                   {blog.date}
                                               </div> */}
                                                </div>

                                                <div className="right">
                                                    {/* <a onClick={() => { window.location.href = `/blog/${blog.id}` }} className="likeCount">Read More</a> */}
                                                    <Link to={`/blog/${firstPost?.postId}`} className="likeCount">Read More</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pinTag">
                                            <PushpinFilled />
                                        </div>
                                    </div>
                                </>
                            }

                            {this.state?.updatedBlogs?.filter((posts: any) => posts.postId !== firstPost?.postId).map((blog: any, index) => {
                                return (
                                    <div className="blogCard">
                                        <div className="image">
                                            <img src={blog.image} alt={blog.image} />
                                        </div>
                                        <div className="details">

                                            <div className="centerSection">
                                                <h3 className="blogTitle">{blog.title}</h3>
                                                <div className="shortBrief">
                                                    <div dangerouslySetInnerHTML={{ __html: blog.richPost.substr(0, 210).trim().concat("...") }} />
                                                    {/* {blog.richPost.slice(0, 180)}.. */}
                                                </div>
                                            </div>
                                            <div className="bottomSection">
                                                <div className="left">
                                                    {/* <div className="date">
                                                   {blog.date}
                                               </div> */}
                                                </div>

                                                <div className="right">
                                                    {/* <a onClick={() => { window.location.href = `/blog/${blog.id}` }} className="likeCount">Read More</a> */}
                                                    <Link to={`/blog/${blog?.postId}`} className="likeCount">Read More</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        :
                        <Spin size="large" tip="Loading.." />
                    }

                </div>
                <Footer />
            </div>
        )
    }
}
export default Blogs;

{/* <div className="blogCard">
    <div className="image">
        <img src="https://static.wixstatic.com/media/4422f85518ce4b08b3ba289d6e190b6f.jpg/v1/fill/w_454,h_341,fp_0.50_0.50,q_90,enc_auto/4422f85518ce4b08b3ba289d6e190b6f.webp" alt="" />
    </div>
    <div className="details">
        <div className="topSection">
            <div className="left">
                <div className="pfp">
                    <svg width="40" height="40" viewBox="0 0 1000 1000" className='wY7WY'>
                        <circle cx="500" cy="500" r="500" fill="#cccccc"></circle>
                        <path fill="#a0a09f" d="M830.8,874.927c-77.344-60.8-187.181-104.877-227.88-111.347-20.335-3.233-20.8-59.1-20.8-59.1s59.746-59.106,72.768-138.584c35.029,0,56.666-84.5,21.631-114.226C677.986,420.37,721.551,206,501,206S324.015,420.37,325.473,451.666c-35.033,29.729-13.4,114.226,21.632,114.226,13.021,79.478,72.77,138.584,72.77,138.584s-0.464,55.871-20.8,59.1c-40.883,6.5-151.537,50.943-228.934,112.176C65.84,784.12,0,649.751,0,500,0,223.858,223.858,0,500,0s500,223.858,500,500C1000,649.3,934.559,783.311,830.8,874.927ZM500,1000h0Z"></path>
                    </svg>
                </div>
                <div className="shortDetails">
                    <div className="userName">Contact32156</div>
                    <div className="timeDetails">
                        <div className="date">Feb 31 . </div>
                        <div className="postedAgo">&nbsp; 2 min</div>
                    </div>
                </div>
            </div>
            <div className="right">
                <div className="moreButton">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" viewBox="0 0 19 19" role="img" className="blog-post-homepage-description-fill blog-post-homepage-link-hashtag-hover-fill">
                        <path d="M2.44398805,5.99973295 C1.62345525,5.9690612 0.980075653,5.28418875 1.00047182,4.46312144 C1.02086799,3.64205413 1.69745853,2.98998831 2.51850166,3.0001164 C3.33954478,3.01024449 3.99985313,3.67880182 4,4.50012255 C3.98424812,5.34399206 3.28763905,6.0153508 2.44398805,5.99973295 L2.44398805,5.99973295 Z M2.44398805,10.9997329 C1.62345525,10.9690612 0.980075653,10.2841888 1.00047182,9.46312144 C1.02086799,8.64205413 1.69745853,7.98998831 2.51850166,8.0001164 C3.33954478,8.01024449 3.99985313,8.67880182 4,9.50012255 C3.98424812,10.3439921 3.28763905,11.0153508 2.44398805,10.9997329 L2.44398805,10.9997329 Z M2.44398805,15.9997329 C1.62345525,15.9690612 0.980075653,15.2841888 1.00047182,14.4631214 C1.02086799,13.6420541 1.69745853,12.9899883 2.51850166,13.0001164 C3.33954478,13.0102445 3.99985313,13.6788018 4,14.5001225 C3.98424812,15.3439921 3.28763905,16.0153508 2.44398805,15.9997329 L2.44398805,15.9997329 Z"></path>
                    </svg>
                </div>
            </div>
        </div>
        <div className="centerSection">
            <h3 className="blogTitle">How to make the most out of remote meetings</h3>
            <div className="shortBrief">
                To create and manage your own content, open the Blog Manager by hovering over your blog feed and clicking Manage. Here you can create,...
            </div>
        </div>
        <div className="bottomSection">
            <div className="left">
                <div className="viewsCount">
                    2 Views
                </div>
                <div className="commentsCount">
                    0 Comments
                </div>
            </div>

            <div className="right">
                <a className="likeCount">Read More</a>
                <div className="like">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" viewBox="0 0 19 19" role="img">
                        <path d="M9.44985848,15.5291774 C9.43911371,15.5362849 9.42782916,15.5449227 9.41715267,15.5553324 L9.44985848,15.5291774 Z M9.44985848,15.5291774 L9.49370677,15.4941118 C9.15422701,15.7147757 10.2318883,15.0314406 10.7297038,14.6971183 C11.5633567,14.1372547 12.3827081,13.5410755 13.1475707,12.9201001 C14.3829188,11.9171478 15.3570936,10.9445466 15.9707237,10.0482572 C16.0768097,9.89330422 16.1713564,9.74160032 16.2509104,9.59910798 C17.0201658,8.17755699 17.2088969,6.78363112 16.7499013,5.65913129 C16.4604017,4.81092573 15.7231445,4.11008901 14.7401472,3.70936139 C13.1379564,3.11266008 11.0475663,3.84092251 9.89976068,5.36430396 L9.50799408,5.8842613 L9.10670536,5.37161711 C7.94954806,3.89335486 6.00516066,3.14638251 4.31830373,3.71958508 C3.36517186,4.00646284 2.65439601,4.72068063 2.23964629,5.77358234 C1.79050315,6.87166888 1.98214559,8.26476279 2.74015555,9.58185512 C2.94777753,9.93163559 3.23221417,10.3090129 3.5869453,10.7089994 C4.17752179,11.3749196 4.94653811,12.0862394 5.85617417,12.8273544 C7.11233096,13.8507929 9.65858244,15.6292133 9.58280954,15.555334 C9.53938013,15.5129899 9.48608859,15.5 9.50042471,15.5 C9.5105974,15.5 9.48275828,15.5074148 9.44985848,15.5291774 Z"></path>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</div> */}