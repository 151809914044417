import React, { Component } from 'react'
import Footer from '../Components/Footer';
import Topbar from '../Components/Topbar';
import wheelOnly from '../images/wheel2.png';
import { Link } from 'react-router-dom';

class Quadrant extends Component {

    toggleDiv = (e: any) => {
        e.target.classList.toggle('active');
        const elements = document.getElementsByClassName('boxx')
        Array.from(elements).map((element: any) => {
            if (element.classList.contains('active')) {
                return
            } else {
                element.classList.add('none')
            }
        })
    }
    render() {
        return (
            <>
                <div className="mainWrapper">
                    {/* <Topbar /> */}

                    {/* <div className="contentContainer"> */}
                        <div className="quadrant">
                            <div className="top">
                    
                                <Link to="/home" className="topLeft boxx">
                                    <h1>tarot with <br /> dr. Shoba</h1>
                                </Link>
                                <Link to="/readings" className="topRight boxx" >
                                    <h1>discover your reading</h1>
                                </Link>
                            </div>
                            <div className="bottom">
                                <Link to="/about" className="bottomLeft boxx">
                                    <h1>my story</h1>
                                </Link>

                                <Link to="/contact" className="bottomRight boxx">
                                    <h1>book your reading</h1>
                                </Link>

                            </div>

                            {/* <div className="centerImage"> */}
                            <img className='centerImage rotate' src={wheelOnly} alt="" />
                            {/* </div> */}

                            <Link to="/blogs" className='blogLink'>Blog - Wordplay with Tarot</Link>
                        </div>
                    {/* </div> */}
                    {/* <Footer /> */}
                </div>
            </>
        )
    }
}
export default Quadrant;

